import '../scss/main.scss';
import { langArr } from './lang';

import socialDeskBgRu from '../img/social-bg-r.svg';
import socialDeskBgEn from '../img/social-bg-e.svg';
import tokenomicsImgR from '../img/tokenomics-img-r.svg';
import tokenomicsImgRmd from '../img/tokenomics-img-r-md.svg';
import tokenomicsImgRlg from '../img/tokenomics-img-r-lg.svg';
import tokenomicsImgRxl from '../img/tokenomics-img-r-xl.svg';
import tokenomicsImgRxxl from '../img/tokenomics-img-r-xxl.svg';
import tokenomicsImgE from '../img/tokenomics-img-e.svg';
import tokenomicsImgEmd from '../img/tokenomics-img-e-md.svg';
import tokenomicsImgElg from '../img/tokenomics-img-e-lg.svg';
import tokenomicsImgExl from '../img/tokenomics-img-e-xl.svg';
import tokenomicsImgExxl from '../img/tokenomics-img-e-xxl.svg';
document.addEventListener('DOMContentLoaded', () => {
  const burger = document.querySelector('.btn--burger');
  const nav = document.querySelector('.nav');
  const btnLangRu = document.querySelector('#btnLangRu');
  const btnLangEn = document.querySelector('#btnLangEn');
  const btnMobLangRu = document.querySelector('#btnMobLangRu');
  const btnMobLangEn = document.querySelector('#btnMobLangEn');
  const menuLinks = document.querySelectorAll('.menu__link');
  const dataLangArr = document.querySelectorAll('.data-lang');
  const socialTg = document.querySelectorAll('.socialTg');
  const socialDzen = document.querySelectorAll('.socialDzen');
  const socialInst = document.querySelectorAll('.socialInst');
  const socialMedium = document.querySelectorAll('.socialMedium');
  const socialDesk = document.querySelector('.social--desk');
  const btnGameDetails = document.querySelector('.btn--details');
  const btnBlog = document.querySelector('.btn--blog');
  const btnCommunity = document.querySelector('.btn--community');
  const cookiesNotice = document.querySelector('.cookiesNotice');
  const termsOfUse = document.querySelector('.termsOfUse');
  const privacyPolicy = document.querySelector('.privacyPolicy');
  const btnClose = document.querySelector('.video__close');
  const btnOpen = document.querySelector('.video__open');
  const videoWrap = document.querySelector('.video__wrap');
  const video = document.querySelector('video');
  const tokenomicsImg = document.querySelector('.tokenomics__img img');
  const btnAppStoreHome = document.querySelector('.btn--app-store-home');
  const btnGooglePlayHome = document.querySelector('.btn--google-play-home');
  const btnAppStoreVideo = document.querySelector('.btn--app-store-video');
  const btnGooglePlayVideo = document.querySelector('.btn--google-play-video');
  const btnAppStoreWTs = document.querySelector('.btn--app-store-wTs');
  const btnGooglePlayWTs = document.querySelector('.btn--google-play-wTs');
  const btnMarketplace = document.querySelector('.btn--marketplace');

  const btnHeaderYoutube = document.querySelector(
    '.social__link--header-youtube'
  );
  const btnHeaderTwitter = document.querySelector(
    '.social__link--header-twitter'
  );
  const btnHeaderDiscord = document.querySelector(
    '.social__link--header-discord'
  );
  const btnHeaderMedium = document.querySelector(
    '.social__link--header-medium'
  );
  const btnHeaderTelegram = document.querySelector(
    '.social__link--header-telegram'
  );

  const btnFooterYoutube = document.querySelector(
    '.social__link--footer-youtube'
  );
  const btnFooterTwitter = document.querySelector(
    '.social__link--footer-twitter'
  );
  const btnFooterDiscord = document.querySelector(
    '.social__link--footer-discord'
  );
  const btnFooterMedium = document.querySelector(
    '.social__link--footer-medium'
  );
  const btnFooterTelegram = document.querySelector(
    '.social__link--footer-telegram'
  );

  burger.addEventListener('click', () => {
    burger.classList.toggle('is-active');
    nav.classList.toggle('is-active');
    document.querySelector('body').classList.toggle('is-lock');
  });
  menuLinks.forEach((link) => {
    link.addEventListener('click', () => {
      nav.classList.remove('is-active');
      burger.classList.remove('is-active');
      document.querySelector('body').classList.remove('is-lock');
    });
  });
  btnOpen.addEventListener('click', () => {
    videoWrap.classList.add('visible');
    video.play();
  });
  btnClose.addEventListener('click', () => {
    videoWrap.classList.remove('visible');
    video.pause();
  });

  video.addEventListener('ended', () => {
    videoWrap.classList.remove('visible');
  });

  let lang =
    (window.hasOwnProperty('localStorage') &&
      window.localStorage.getItem('lang', lang)) ||
    'en';
  const setLang = (lang) => {
    document.documentElement.lang = lang;
    let data;
    if (!langArr.hasOwnProperty(lang)) return;
    if (window.hasOwnProperty('localStorage'))
      window.localStorage.setItem('lang', lang);

    lang === 'en'
      ? (socialDesk.style.cssText = `background: url('${socialDeskBgEn}') no-repeat;
      height: 302px`) &&
        (btnGameDetails.href = 'https://docs.cosmicfomo.com/') &&
        (btnBlog.href =
          'https://medium.com/@CosmicFomo?utm_source=website&utm_medium=website&utm_campaign=cosmicfomo') &&
        (btnCommunity.href = 'https://t.me/+5EGZJtH0yRAwYjAy') &&
        (cookiesNotice.href =
          'https://docs.cosmicfomo.com/legal/cookies-notice') &&
        (termsOfUse.href = 'https://docs.cosmicfomo.com/legal/terms-of-use') &&
        (privacyPolicy.href =
          'https://docs.cosmicfomo.com/legal/privacy-policy')
      : (socialDesk.style = `background: url('${socialDeskBgRu}') no-repeat;
      height: 362px`) &&
        (btnGameDetails.href =
          'https://docs.cosmicfomo.com/v/cosmic-paperr-1/') &&
        (btnBlog.href =
          'https://dzen.ru/id/635f9d74f8f3a1185fc6d009?utm_source=website&utm_medium=website&utm_campaign=cosmicfomo') &&
        (btnCommunity.href = 'https://t.me/+fIdayWcWXkhiNWJi') &&
        (cookiesNotice.href =
          'https://docs.cosmicfomo.com/v/cosmic-paperr-1/yuridicheskaya-informaciya/faily-cookie') &&
        (termsOfUse.href =
          'https://docs.cosmicfomo.com/v/cosmic-paperr-1/yuridicheskaya-informaciya/polzovatelskoe-soglashenie') &&
        (privacyPolicy.href =
          'https://docs.cosmicfomo.com/v/cosmic-paperr-1/yuridicheskaya-informaciya/politika-konfidencialnosti');

    lang === 'en'
      ? socialInst.forEach((el) => (el.style = 'display: none'))
      : socialInst.forEach((el) => (el.style = 'display: block'));
    lang === 'en'
      ? socialDzen.forEach((el) => (el.style = 'display: none'))
      : socialDzen.forEach((el) => (el.style = 'display: block'));
    lang === 'en'
      ? socialMedium.forEach((el) => (el.style = 'display: block'))
      : socialMedium.forEach((el) => (el.style = 'display: none'));
    lang === 'en'
      ? socialTg.forEach(
          (el) => (el.firstElementChild.href = 'https://t.me/+5EGZJtH0yRAwYjAy')
        )
      : socialTg.forEach(
          (el) => (el.firstElementChild.href = 'https://t.me/+fIdayWcWXkhiNWJi')
        );
    lang === 'en'
      ? (btnMobLangRu.style = 'display: none') &&
        (btnMobLangEn.style = 'display: flex')
      : (btnMobLangRu.style = 'display: flex') &&
        (btnMobLangEn.style = 'display: none');

    if (window.innerWidth >= 1280) {
      lang === 'en'
        ? (btnLangRu.style = 'display: none') &&
          (btnLangEn.style = 'display: flex')
        : (btnLangRu.style = 'display: flex') &&
          (btnLangEn.style = 'display: none');
    }

    lang === 'ru' && window.innerWidth >= 1920
      ? (tokenomicsImg.src = `${tokenomicsImgRxxl}`)
      : lang === 'ru' && window.innerWidth >= 1440
      ? (tokenomicsImg.src = `${tokenomicsImgRxl}`)
      : lang === 'ru' && window.innerWidth >= 1280
      ? (tokenomicsImg.src = `${tokenomicsImgRlg}`)
      : lang === 'ru' && window.innerWidth >= 744
      ? (tokenomicsImg.src = `${tokenomicsImgRmd}`)
      : lang === 'ru' && window.innerWidth >= 0
      ? (tokenomicsImg.src = `${tokenomicsImgR}`)
      : lang === 'en' && window.innerWidth >= 1920
      ? (tokenomicsImg.src = `${tokenomicsImgExxl}`)
      : lang === 'en' && window.innerWidth >= 1440
      ? (tokenomicsImg.src = `${tokenomicsImgExl}`)
      : lang === 'en' && window.innerWidth >= 1280
      ? (tokenomicsImg.src = `${tokenomicsImgElg}`)
      : lang === 'en' && window.innerWidth >= 744
      ? (tokenomicsImg.src = `${tokenomicsImgEmd}`)
      : lang === 'en' && window.innerWidth >= 0
      ? (tokenomicsImg.src = `${tokenomicsImgE}`)
      : '';

    for (data of dataLangArr) {
      let dataContent = data.getAttribute('data-lang');
      for (dataContent in langArr[lang]) {
        data.textContent = langArr[lang][data.getAttribute('data-lang')];
      }
    }
  };

  setLang(lang);
  btnLangRu.addEventListener('click', () => setLang('en'));
  btnLangEn.addEventListener('click', () => setLang('ru'));
  btnMobLangRu.addEventListener('click', () => setLang('en'));
  btnMobLangEn.addEventListener('click', () => setLang('ru'));

  // Analytics events

  btnAppStoreHome.addEventListener('click', () => {
    ga('send', {
      hitType: 'event',
      eventCategory: 'lead:appstore1',
      eventAction: 'click',
    });
  });

  btnGooglePlayHome.addEventListener('click', () => {
    ga('send', {
      hitType: 'event',
      eventCategory: 'lead:playmarket1',
      eventAction: 'click',
    });
  });

  btnGameDetails.addEventListener('click', () => {
    ga('send', {
      hitType: 'event',
      eventCategory: 'lead:gamedetails',
      eventAction: 'click',
    });
  });

  btnAppStoreVideo.addEventListener('click', () => {
    ga('send', {
      hitType: 'event',
      eventCategory: 'lead:appstore2',
      eventAction: 'click',
    });
  });

  btnGooglePlayVideo.addEventListener('click', () => {
    ga('send', {
      hitType: 'event',
      eventCategory: 'lead:playmarket2',
      eventAction: 'click',
    });
  });

  btnAppStoreWTs.addEventListener('click', () => {
    ga('send', {
      hitType: 'event',
      eventCategory: 'lead:appstore3',
      eventAction: 'click',
    });
  });

  btnGooglePlayWTs.addEventListener('click', () => {
    ga('send', {
      hitType: 'event',
      eventCategory: 'lead:playmarket3',
      eventAction: 'click',
    });
  });

  btnMarketplace.addEventListener('click', () => {
    ga('send', {
      hitType: 'event',
      eventCategory: 'lead:marketplace',
      eventAction: 'click',
    });
  });

  btnBlog.addEventListener('click', () => {
    ga('send', {
      hitType: 'event',
      eventCategory: 'lead:blog',
      eventAction: 'click',
    });
  });

  btnCommunity.addEventListener('click', () => {
    ga('send', {
      hitType: 'event',
      eventCategory: 'lead:community',
      eventAction: 'click',
    });
  });

  btnHeaderYoutube.addEventListener('click', () => {
    ga('send', {
      hitType: 'event',
      eventCategory: 'social:yotube1',
      eventAction: 'click',
    });
  });
  btnHeaderTwitter.addEventListener('click', () => {
    ga('send', {
      hitType: 'event',
      eventCategory: 'social:twitter1',
      eventAction: 'click',
    });
  });
  btnHeaderDiscord.addEventListener('click', () => {
    ga('send', {
      hitType: 'event',
      eventCategory: 'social:discord1',
      eventAction: 'click',
    });
  });
  btnHeaderMedium.addEventListener('click', () => {
    ga('send', {
      hitType: 'event',
      eventCategory: 'social:medium1',
      eventAction: 'click',
    });
  });
  btnHeaderTelegram.addEventListener('click', () => {
    ga('send', {
      hitType: 'event',
      eventCategory: 'social:telegram1',
      eventAction: 'click',
    });
  });

  btnFooterYoutube.addEventListener('click', () => {
    ga('send', {
      hitType: 'event',
      eventCategory: 'social:yotube2',
      eventAction: 'click',
    });
  });
  btnFooterTwitter.addEventListener('click', () => {
    ga('send', {
      hitType: 'event',
      eventCategory: 'social:twitter2',
      eventAction: 'click',
    });
  });
  btnFooterDiscord.addEventListener('click', () => {
    ga('send', {
      hitType: 'event',
      eventCategory: 'social:discord2',
      eventAction: 'click',
    });
  });
  btnFooterMedium.addEventListener('click', () => {
    ga('send', {
      hitType: 'event',
      eventCategory: 'social:medium2',
      eventAction: 'click',
    });
  });
  btnFooterTelegram.addEventListener('click', () => {
    ga('send', {
      hitType: 'event',
      eventCategory: 'social:telegram2',
      eventAction: 'click',
    });
  });
});
