export const langArr = {
  en: {
    homeSubtitleOne: 'is an app that allows learning',
    homeSubtitleTwo: 'crypto trading in game form',
    homeSubtitleThree: 'and earning without risking',
    homeSubtitleFour: 'your actual assets',
    gameplay: 'Gameplay',
    gt4: 'GT4 Collection',
    downloadAPK: 'Download APK',
    cryptocurrencyTitleOne: 'Join and start',
    cryptocurrencyTitleTwo: 'exploring the world  ',
    cryptocurrencyTitleThree: 'cryptocurrency',
    cryptocurrencyFirstTextOne: 'Our mission is to simplify the transition',
    cryptocurrencyFirstTextTwo: ' to the world of digital assets and bring ',
    cryptocurrencyFirstTextThree: 'mass adoption',
    cryptocurrencyFirstTextFour: ' closer.',
    cryptocurrencySecondText:
      'With the Cosmic FOMO app, in an enjoyable game form, you will be able to:',
    cryptocurrencyOneItem:
      'Take your first steps into secure cryptocurrency management',
    cryptocurrencyTwoItem: 'Master the cryptocurrency market mechanics',
    cryptocurrencyThreeItem: 'Understand the subtleties of trading',
    cryptocurrencyFourItem:
      'Deepen your knowledge and join a community of like-minded people',
    howToPlayTitle: 'How to play Cosmic FOMO',
    howToPlayOneItem: 'Choose your pilot',
    howToPlayTwoItem: 'Fill your backpack with assets',
    howToPlayThreeItem: 'Send your pilot on a space flight',
    howToPlayFourItem: 'Get rewards and in-game tokens',
    tokenomicsTitle: 'Cosmic FOMO Tokenomics',
    tokenomicsSubtitle: 'is part of the MarsDAO ecosystem',
    gametoken: 'In-game token',
    utilitytoken: 'Utility token',
    tokenomicsFirstText:
      'Is always credited for wins and losses in the game, and helps to upgrade pilots',
    tokenomicsSecondText:
      'Grants access to various game features and is burned in the process',
    videoTitleOne: 'Explore the new realities',
    videoTitleTwo: 'of the world in a fun way',
    videoText: 'Without spending a fortune or risking your real assets',
    infoTitle: 'Joint NFT collection with partners',
    infoNftTextFirst: 'with a unique design',
    infoNftTextLast: 'and signature branding',
    infoListOneText: 'Attend the entire 2023 racing series for free',
    infoListTwoText: 'Go behind the scenes of GT4 races in Germany',
    infoListThreeText: 'Meet the team at the MarsDAO Meetup',
    whereToStartTitle: 'Where to start? 3 easy steps',
    whereToStartOneSubtitle: 'Download the Cosmic FOMO app',
    whereToStartTwoSubtitle: 'Choose your pilot for flights',
    whereToStartThreeSubtitle: 'Start exploring the crypto world',
    whereToStartOneText1: 'Download the app and',
    whereToStartOneText2: '  sign up  ',
    whereToStartOneText3: 'in 2 clicks',
    whereToStartTwoText1: 'Choose the pilot you want',
    whereToStartTwoText2: ' or pick a capsule  ',
    whereToStartTwoText3: ' with a pilot without knowing their  ',
    whereToStartTwoText4: 'characteristics',
    whereToStartThreeText1: 'Play and learn useful stuff',
    whereToStartThreeText2: ' from the blog, and ask questions ',
    whereToStartThreeText3: 'to the community',
    partnerstTitle: 'Our partners',
    docsOneItem: 'Cookies Notice',
    docsTwoItem: 'Terms of Use',
    docsThreeItem: 'Privacy Policy',
    btnTokenDetails: 'Token details',
    btnGameDetails: 'Game details',
    btnGT4: 'GT4 Collection',
    btnMarketplace: 'Marketplace',
    btnBlog: 'Blog',
    btnCommunity: 'Community',
    btnCollection: 'Collection details',
  },

  ru: {
    homeSubtitleOne: '— это приложение, позволяющее',
    homeSubtitleTwo: 'обучаться криптотрейдингу',
    homeSubtitleThree: 'в игровой форме и зарабатывать,',
    homeSubtitleFour: 'не рискуя собственными активами.',
    gameplay: 'Как играть',
    gt4: 'Коллекция GT4',
    downloadAPK: 'Скачать APK',
    cryptocurrencyTitleOne: 'Присоединяйтесь',
    cryptocurrencyTitleTwo: ' и начинайте изучать мир',
    cryptocurrencyTitleThree: ' криптовалют',
    cryptocurrencyFirstTextOne: 'Наша миссия — упростить переход ',
    cryptocurrencyFirstTextTwo: ' в мир цифровых активов и приблизить ',
    cryptocurrencyFirstTextThree: 'mass adoption.',
    cryptocurrencyFirstTextFour: '',
    cryptocurrencySecondText:
      'С помощью приложения Cosmic FOMO в простой игровой форме вы сможете:',
    cryptocurrencyOneItem:
      'Сделать первые шаги в безопасной работе с криптовалютами',
    cryptocurrencyTwoItem: 'Освоить механику работы рынка криптовалют',
    cryptocurrencyThreeItem: 'Разобраться в тонкостях трейдинга',
    cryptocurrencyFourItem:
      'Углубить свои знания и присоединиться к сообществу единомышленников',
    howToPlayTitle: 'Как играть в Cosmic FOMO',
    howToPlayOneItem: 'Выбирайте пилота',
    howToPlayTwoItem: 'Заправляйте рюкзак активами',
    howToPlayThreeItem: 'Отправляйте пилота в полет',
    howToPlayFourItem: 'Получайте вознаграждения и игровые токены',
    tokenomicsTitle: 'Токеномика игры Cosmic FOMO',
    tokenomicsSubtitle: '— часть экосистемы MarsDAO',
    gametoken: 'Игровой токен',
    utilitytoken: 'Утилитарный токен',
    tokenomicsFirstText:
      'Всегда начисляется за победы или проигрыши в игре, помогает прокачивать пилотов',
    tokenomicsSecondText:
      'Позволяет получать доступ к различным функциям игры и сжигается в процессе',
    videoTitleOne: 'Обучайтесь новым реалиям',
    videoTitleTwo: 'мира в легкой игровой форме',
    videoText:
      'При этом не тратя целое состояние и не рискуя собственными активами',
    infoTitle: 'Совместная NFT-коллекция с партнерами',
    infoNftTextFirst: 'c уникальным дизайном',
    infoNftTextLast: 'и партнерской символикой',
    infoListOneText: 'Посетите всю серию гонок 2023 года бесплатно',
    infoListTwoText: 'Пройдите за кулисы гонок GT4 в Германии',
    infoListThreeText: 'Познакомьтесь с командой на MarsDAO Meetup',
    whereToStartTitle: 'С чего начать? 3 простых шага',
    whereToStartOneSubtitle: 'Скачать приложение Cosmic FOMO',
    whereToStartTwoSubtitle: 'Получить пилота для полетов',
    whereToStartThreeSubtitle: 'Начать погружаться в криптомир',
    whereToStartOneText1: 'Скачайте приложение',
    whereToStartOneText2: '  и зарегистрируйтесь  ',
    whereToStartOneText3: 'в 2 клика',
    whereToStartTwoText1: 'Выберите пилота на ваш вкус',
    whereToStartTwoText2: ' или возьмите капсулу  ',
    whereToStartTwoText3: ' с пилотом, не зная его  ',
    whereToStartTwoText4: 'характеристик',
    whereToStartThreeText1: 'Играйте и изучайте полезные',
    whereToStartThreeText2: ' материалы в блоге, задавайте  ',
    whereToStartThreeText3: 'вопросы в сообществе',
    partnerstTitle: 'Наши партнеры',
    docsOneItem: 'Файлы cookie',
    docsTwoItem: 'Пользовательское соглашение',
    docsThreeItem: 'Политика конфиденциальности',
    btnTokenDetails: 'подробнее о токене',
    btnGameDetails: 'Подробнее об игре',
    btnGT4: 'Коллекция GT4',
    btnMarketplace: 'Маркетплейс',
    btnBlog: 'Блог',
    btnCommunity: 'Сообщество',
    btnCollection: 'Подробнее о коллекции',
  },
};
